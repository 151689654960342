<script setup>

</script>

<template>
  <div id="shopping">商城管理</div>
</template>

<style lang="scss" scoped>
#shopping {
  height: 100%;
}
</style>
